<template>
  <b-overlay :show="loading" rounded="sm">
    <b-container fluid class="p-0">
      <!-- Start statistic -->
      <b-row>
        <b-col>
          <div
            class="d-flex justify-content-end align-items-center"
            style="height: 100%"
          >
            <div>
              <multiselect
                class="mutiselect-rounded"
                v-model="selected"
                @select="handleViewDetail"
                :options="options"
                :searchable="false"
                :show-labels="false"
                label="text"
              ></multiselect>
            </div>
            <div class="ml-2">
              <multiselect
                class="mutiselect-rounded"
                v-model="selectedDay"
                :options="optionsDay"
                :searchable="false"
                :show-labels="false"
                label="text"
              ></multiselect>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-tabs active-tab-class="pl-0 pr-0" card class="custom" lazy>
            <!-- Đường huyết -->
            <b-tab class="tab-custom">
              <template #title>
                <div class="d-flex align-items-end">
                  <span>Đường huyết</span>
                </div>
              </template>
              <blood-sugar :diabeteText="getDiabeteText" :userId="patientId" :selectDay="viewByDay" />
            </b-tab>
            <!-- Đường huyết -->

            <!-- Huyết áp -->
            <b-tab>
              <template #title>
                <div class="d-flex align-items-end">
                  <span>Huyết Áp</span>
                </div>
              </template>
              <blood-pressure :userId="patientId" :selectDay="viewByDay" />
            </b-tab>
            <!-- Huyết áp -->

            <b-tab>
              <template #title>
                <div class="d-flex align-items-end">
                  <span>Vận động</span>
                </div>
              </template>
              <exercise :userId="patientId" :selectDay="viewByDay" />
            </b-tab>
            <b-tab>
              <template #title>
                <div class="d-flex align-items-end">
                  <span>Dinh dưỡng</span>
                </div>
              </template>
              <food :userId="patientId" :selectDay="viewByDay" />
            </b-tab>
            <b-tab>
              <template #title>
                <div class="d-flex align-items-end">
                  <span>Cân nặng</span>
                </div>
              </template>
              <weight :userId="patientId" :selectDay="viewByDay" />
            </b-tab>
            <b-tab>
              <template #title>
                <div class="d-flex align-items-end">
                  <span>Cảm xúc</span>
                </div>
              </template>
              <feeling :userId="patientId" :selectDay="viewByDay" />
            </b-tab>
            <b-tab>
              <template #title>
                <div class="d-flex align-items-end">
                  <span>HbA1c</span>
                </div>
              </template>
              <hba1c :userId="patientId" :selectDay="viewByDay" />
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
      <input-detail :selected.sync="selected" :data="item" />
    </b-container>
  </b-overlay>
</template>
<script>
import Multiselect from 'vue-multiselect';
import { createNamespacedHelpers } from 'vuex';
const { mapState } = createNamespacedHelpers('userDashboard');
export default {
  name: 'Biological',
  components: {
    Multiselect,
    'blood-pressure': () =>
      import('../../coaches_experts/tabs/bloodpressure.vue'),
    'blood-sugar': () => import('../../coaches_experts/tabs/bloodsugar'),
    exercise: () => import('../../coaches_experts/tabs/exercises'),
    hba1c: () => import('../../coaches_experts/tabs/hba1c'),
    weight: () => import('../../coaches_experts/tabs/weight'),
    feeling: () => import('../../coaches_experts/tabs/feeling'),
    food: () => import('../../coaches_experts/tabs/food'),
    'input-detail': () => import('../../coaches_experts/modalnputDetail'),
  },
  computed: {
    ...mapState({ item: 'customerInfo' }),
    // item() {
    //   return this.user || {};
    // },
    viewByDay() {
      return this.selectedDay.value;
    },
    patientId() {
      return this.$route.params.id;
    },
    getDiabeteText() {
      const diabete = this.item?.levelOfDiabetesRuleList?.find(
        (el) => el.selected,
      );
      return diabete?.text;
    },
  },
  data() {
    return {
      isLoaded: true,
      loading: false,
      statistic: null,
      trendSelected: { text: 'Sau ăn', value: 'after' },
      trendOptions: [
        { text: 'Trước ăn', value: 'before' },
        { text: 'Sau ăn', value: 'after' },
      ],
      selectedDay: { text: 'Thống kê 7 ngày gần nhất', value: 1 },
      selected: { text: 'Xem dữ liệu nhập chi tiết', value: null },
      optionsDay: [
        { text: 'Thống kê 7 ngày gần nhất', value: 1 },
        { text: 'Thống kê 14 ngày gần nhất', value: 2 },
        { text: 'Thống kê 30 ngày gần nhất', value: 3 },
        { text: 'Thống kê 90 ngày gần nhất', value: 4 },
      ],
      options: [
        // { text: 'Xem dữ liệu nhập chi tiết', value: null },
        { text: 'Đường huyết', value: 'blood-sugar' },
        { text: 'Huyết Áp', value: 'blood-pressure' },
        { text: 'Vận động', value: 'exercise-table' },
        { text: 'Dinh dưỡng', value: 'food-table' },
        { text: 'Cân nặng', value: 'weight-table' },
        { text: 'Cảm xúc', value: 'feeling-table' },
        { text: 'HbA1c', value: 'hba1c-table' },
      ],
    };
  },
  mounted() {},
  methods: {
    async openStaffPopup() {
      try {
        this.loading = true;
        let { data } = await this.$api.get('Admin/Home', {
          params: { patientId: this.patientId },
        });
        const {
          bloodPressureIndex,
          emotionCard,
          glucoseIndex,
          weightCard,
          hbA1CIndex,
          energyCard,
          exercise,
        } = data;
        let bloodPressure = {
          label: 'Huyết áp',
          color: bloodPressureIndex.color,
          value: `${bloodPressureIndex.systolic}/${bloodPressureIndex.diastolic} `,
          time: bloodPressureIndex.createDateTime,
          unit: bloodPressureIndex.unit || 'mm/Hg',
        };
        let bloodSugar = {
          label: 'Đường huyết',
          color: glucoseIndex.color,
          value: `${glucoseIndex.index} `,
          time: glucoseIndex.createDateTime,
          unit: glucoseIndex.unit || 'mg/DL',
        };
        let hbA1C = {
          label: 'HbA1c',
          color: hbA1CIndex.color,
          value: `${hbA1CIndex.index} `,
          time: hbA1CIndex.createDateTime,
          unit: '%',
        };
        let emotion = {
          label: 'Cảm xúc',
          color: emotionCard.color || '#000',
          image: emotionCard?.details?.[0]?.icon?.url,
          value: emotionCard?.details?.[0].text,
          time: emotionCard.emotionDateTime,
        };
        let food = {
          label: 'Dinh dưỡng',
          color: energyCard.color,
          value: `${energyCard.consumedEnergy} `,
          time: energyCard.consumedEnergyDateTime,
          unit: energyCard.unit || 'kcal',
        };
        let weight = {
          label: 'Cân nặng',
          color: weightCard.color,
          value: `${weightCard.weight} `,
          time: weightCard.weightDateTime,
          unit: weightCard.unit || 'kg',
        };
        let pulseRate = {
          label: 'Nhịp tim',
          color: '#000',
          value: `${bloodPressureIndex.pulseRate}`,
          time: bloodPressureIndex.createDateTime,
          unit: bloodPressureIndex.unit || 'lần/phút',
        };
        let exerciseIndex = {
          label: 'Vận động',
          color: exercise.color,
          value: `${exercise.index} `,
          time: exercise.createDateTime,
          unit: exercise.unit || 'kcal',
        };
        this.statistic = [
          bloodSugar,
          bloodPressure,
          pulseRate,
          exerciseIndex,
          food,
          weight,
          emotion,
          hbA1C,
        ];
        this.loading = false;
      } catch (error) {
        this.loading = false;
      } finally {
        this.isLoaded = false;
      }
    },
    handleViewDetail() {
      this.$bvModal.show('input-modal');
    },
  },
};
</script>

<style lang="scss">
.mutiselect-rounded {
  width: 210px;
  text-align: center;
  color: #008479;
}
.mutiselect-rounded {
  .multiselect__tags {
    border-radius: 50px !important;
    border-color: #008479;
  }
}
.custom {
  a.nav-link {
    border: 0;
    &.active {
      font-weight: 600;
      color: #01645a !important;
      border-bottom: 2px solid #01645a;
    }
  }
  li.nav-item {
    a.nav-link:hover {
      margin-bottom: 0 !important;
      color: #333;
      border: 0 !important;
    }
  }
}
.full-width {
  width: 100vw !important;
  max-width: 1440px !important;
}
#dashboard-modal {
  padding: 0 !important;
}
</style>
<style lang="scss" scoped>
.statistic {
  width: 100%;
  &__content {
    // border-right: 1px solid #ebedf3;
  }
  &__label {
    font-weight: 700;
    color: #b5b5c3;
  }
  &__time {
    color: #b5b5c3;
  }
  .value {
    &__main {
      font-weight: 700;
    }
    &__unit {
      color: #515356;
    }
  }
}
.card {
  background-color: #fcfcfc;
  border-radius: 16px;
}
</style>
